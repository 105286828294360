import { highlightColor } from "src/styles/highlightColor";
import { figmaHelper } from "src/styles/typography";
import type { ComponentStyleConfig } from "@chakra-ui/theme";

const DashboardContent: ComponentStyleConfig = {
	baseStyle: {
		wrapper: {
			color: "white",
			paddingY: 2,
			position: "relative",
			overflow: "hidden",
			borderBottomWidth: "1rem",
			borderBottomColor: highlightColor,
			zIndex: "base",
		},
		title: {
			paddingTop: 24,
			paddingBottom: 5,
			fontStyle: "italic",
			fontWeight: "normal",
			letterSpacing: "-0.01em",
			...figmaHelper({ base: 34, lg: 42 }, { base: 39, lg: 56 }),
			fontFamily: "serif",
		},
		content: {
			position: "relative",
			zIndex: "2",
		},
		welcome: {
			paddingBottom: 6,
			minHeight: 28,
			maxWidth: "39rem",
			fontWeight: "semibold",
		},
		cardsWrapper: {
			paddingTop: 8,
			paddingBottom: 20,

			display: "flex",
			flexDirection: "row",
			flexWrap: "wrap",
			gap: 6,

			"> div": {
				flex: "1 1 calc(100% / 3)",
			},
		},
		shapeWrapper: {
			zIndex: "hide",
			position: "absolute",
			top: 0,
			right: 0,
			// width specified by design
			w: "32rem",
			maxWidth: "70%",
		},
		shape: {
			w: "100%",
			h: "100%",
		},
		carouselWrapper: {
			"& .swiper": {
				width: "100vw",
			},
			"& .swiper-wrapper": {
				marginTop: 4,
				marginBottom: 12,
			},

			"& .swiper-slide.swiper-slide": {
				bg: "none",
				textAlign: "left",
				opacity: 1,
				flexBasis: {
					base: "min-content",
					sm: "fit-content",
				},
				":last-child": {
					marginRight: 12,
				},
			},
		},
	},
	variants: {
		broker: {
			cardsWrapper: {
				paddingTop: 10,
				paddingBottom: 4,
				gap: { base: 6, lg: 14 },
				justifyContent: "center",
				alignItems: "stretch",
			},
			title: {
				paddingTop: 20,
				paddingBottom: 10,
			},
			contentTitle: {
				marginBottom: 20,
				fontFamily: "sans",
			},
		},
		mobile: {
			cardsWrapper: {
				paddingTop: 8,
				paddingBottom: 4,
			},
		},
	},
};

export default DashboardContent;
