import { figmaHelper } from "../../styles/typography";
import type { ComponentStyleConfig } from "@chakra-ui/theme";

const Attachment: ComponentStyleConfig = {
	baseStyle: {
		wrapper: {},
		title: {
			fontFamily: "sans",
			fontWeight: "bold",
			...figmaHelper(18, 36),
			color: "black",
			hyphens: "auto",
		},
		link: {
			display: "flex",
			gap: 4,
			alignItems: "start",
			textDecoration: "none",
			transitionProperty: "common",
			transitionDuration: "500ms",
			padding: 4,

			_hover: {
				background: "brand.lighter",
			},
		},
		createdAt: {
			...figmaHelper(16, 22),
			fontWeight: "semibold",
			color: "brand.medium",
		},
		metadata: {
			...figmaHelper(16, 26),
			fontWeight: "medium",
			display: "flex",
			gap: 2,
			alignItems: "center",
			color: "brand.dark",
		},
		fileType: {
			textTransform: "uppercase",
		},
		fileIcon: {
			color: "brand.dark",
			width: 7,
			height: "auto",
		},
		downloadIcon: {
			color: "brand.highlight",
			width: 3,
			height: "auto",
		},
		spinner: {
			color: "brand.highlight",
			w: 4,
			h: 4,
			margin: 0,
		},
	},
};

export default Attachment;
