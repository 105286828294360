import type { ComponentStyleConfig } from "@chakra-ui/theme";

const baseStyle: ComponentStyleConfig["baseStyle"] = {
	title: {
		maxW: { base: "none", lg: "50%" },
		paddingBottom: { base: 8, lg: 16 },
	},
	headings: {
		display: "flex",
		flexDirection: "column-reverse",
	},
	kicker: {
		color: "brand.dark",
		maxW: { base: "none", lg: "50%" },
		paddingBottom: { base: 4, lg: 8 },
	},
	topSection: {
		bg: "brand.lighter",
		paddingBottom: 16,
	},
	container: {
		maxW: "container.xl",
		paddingTop: { base: "3rem", lg: "6rem" },
	},
	details: {
		paddingBottom: 12,
	},
	divider: {
		borderWidth: 1,
	},
	actions: {
		paddingBottom: 12,
	},
	actionButtonWrapper: {
		position: "relative",
		textAlign: "center",
	},
	tooltip: {
		position: { base: "static", xl: "absolute" },
		transform: { base: "none", xl: "translateY(-50%)" },
		right: "100%",
		top: "50%",
	},
	actionLink: {
		boxShadow: "none",
		border: "2px solid currentColor",
		fontSize: {
			base: "sm",
			lg: "md",
		},
	},
};

const PolicyDetails: ComponentStyleConfig = {
	parts: Object.keys(baseStyle),
	baseStyle,
};

export default PolicyDetails;
